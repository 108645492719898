// @ts-check
import { computed, ref } from '@vue/composition-api';
import { defineStore } from 'pinia';

import { MODULE_STORE_ID } from '@/constants/storeIds';
import { createModuleService, fetchModuleByIdService, fetchModuleDocumentsService, fetchScreenFieldsMetaService, updateGlobalVariablesInfoService, updateModuleService } from '@/services/application-service/moduleRequests';
import { createModuleTagService, fetchModuleTagsService } from '@/services/version-control-service/versionControlModuleRequests';
import { useModuleGraphStore } from './moduleGraphStore';
import { moduleLintingService } from '@/services/lint-service/lintModuleRequests';
import { createVariableService, deleteVariableWithReferenceService, fetchVariablesService } from '@/services/application-service/variableRequests';
import { GRAPH_VIEW } from '@/modules/builder/constants/module';
import {createNodeTagService} from '@/services/version-control-service/versionControlNodeRequests';
import { fetchEnvironmentVariablesService } from '@/services/application-service/environmentVariableRequest';
import { convertModuleVersionUTCToCestTime } from '@/helpers/util';

//-- composable sub stores definition start --//
const useVersions = () => {
    
    const versions = ref([]);
    const latestVersion = ref('');
    /**
     * @param {string} moduleId 
     */
    const fetchVersions = async (moduleId) => {
        try {
            const { data: { data: moduleTags } } = await fetchModuleTagsService(moduleId);
            for(let i = 0; i < moduleTags.length; i++) {
                moduleTags[i].name = convertModuleVersionUTCToCestTime(moduleTags[i].name);
            }
            if (moduleTags && moduleTags.length) {
                versions.value = moduleTags;
                versions.value[0].name += ' (current/latest version on development)';
            }
        } catch (err) {
            console.error(err);
        }
    };

    const resetVersions = () => {
        versions.value = [];
        latestVersion.value = '';
    };

    return {
        versions,
        latestVersion,
        fetchVersions,
        resetVersions
    };
};

const useVariables = () => {
    // @TO-DO's: add typings
    const moduleVariables = ref([]);
    const moduleVariablesToRetrieve = ref([]);
    const authModuleVariables = ref([]);
    const globalVariablesInfo = ref([]);
    const environmentVariables = ref([]);
    
    const variables = computed(() => moduleVariables.value.concat(environmentVariables.value, authModuleVariables.value));

    /**
     * @param {string} appId 
     * @param {string} moduleId 
     * @param {string[]} referenceIds
     */
    const deleteVariables = async (appId, moduleId, referenceIds) => {
        referenceIds = referenceIds.filter(
            referenceId => moduleVariables.value.some(variable => variable.reference === referenceId)
        );
        await Promise.all(
            referenceIds.map(referenceId => deleteVariableWithReferenceService(appId, moduleId, {
                reference: referenceId
            }))
        );
        await fetchVariables(appId);
    };
    /**
     * @param {string} appId 
     * @param {string} moduleId 
     * @param {import('../../../types/applicationTypes').IVariablePayload} payload
     */
    const createVariable = async (appId, moduleId, payload) => {
        try {
            await createVariableService(appId, moduleId, payload);
            await fetchVariables(appId);
        } catch (err) {
            console.log(err);
        }
    };
    /**
     * @param {string} appId
     */
    const fetchVariables = async (appId) => {
        try {
            const modulesVariables = await Promise.all(
                moduleVariablesToRetrieve.value.map(moduleId => fetchVariablesService(appId, moduleId))
            );
            moduleVariables.value = modulesVariables.flatMap((variables, index) => {
                const { data: { data } } = variables;
                return data;
            });
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * @param {string} appId
     * @param {string} moduleId
     */
    const fetchAuthModuleVariables = async (appId, moduleId) => {
        try {
            const { data: { data } } = await fetchVariablesService(appId, moduleId);
            authModuleVariables.value = data?.filter(variable => variable.node_id) || [];
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * @param {string} appId 
     * @param {string} moduleId
     * @param {object} updatedVariables
     */
    const updateGlobalVariablesInfo = async (appId, moduleId, updatedVariables) => {
        try {
            const { data: { data } } = await updateGlobalVariablesInfoService(appId, moduleId, updatedVariables);
            globalVariablesInfo.value = data.global_variables;
        } catch (err) {
            console.error(err);
        }
    };
    /**
     * @param {string} appId 
     */
    const fetchEnvironmentVariables = async (appId) => {
        try {
            const { data: { data } } = await fetchEnvironmentVariablesService(appId);
            environmentVariables.value = data;
        } catch (err) {
            console.error(err);
        }
    };

    return {
        variables,
        environmentVariables,
        moduleVariables,
        authModuleVariables,
        globalVariablesInfo,
        moduleVariablesToRetrieve,
        updateGlobalVariablesInfo,
        fetchVariables,
        fetchAuthModuleVariables,
        createVariable,
        deleteVariables,
        fetchEnvironmentVariables
    };
};

const useModuleErrors = () => {
    // @TO-DO's: add typings
    const errorMessages = ref([]);
    /**
     * @param {string} moduleId 
     */
    const checkForLintErrors = async (moduleId) => {
        // try {
        //     const { data: { data } } = await moduleLintingService(moduleId);
        //     errorMessages.value = data.messages;
        // } catch (err) {
        //     console.error(err);
        // }
    };

    const resetModuleErrors = () => {
        errorMessages.value = [];
    };

    return {
        errorMessages,
        checkForLintErrors,
        resetModuleErrors
    };
};
//-- composable sub stores definition end --//

export const useModuleStore = defineStore(MODULE_STORE_ID, () => {
    // compose other stores
    const moduleGraphStore = useModuleGraphStore();

    // compose sub stores
    const versionsSubStore = useVersions();
    const variablesSubStore = useVariables();
    const moduleErrorsSubStore = useModuleErrors();

    const { checkForLintErrors } = moduleErrorsSubStore;
    const { latestVersion, fetchVersions } = versionsSubStore;

    const isNewEnvVarModalActive = ref(false);
    const isUpdateEnvVarModalActive = ref(false);
    const isLayoutModalActive = ref(false);
    const currentLayout = ref(null);
    const moduleView = ref(GRAPH_VIEW);
    const moduleDetails = ref(null);
    const shouldAvoidModuleUpdate = ref(false);
    const moduleDocuments = ref(null);
    const moduleId = ref('');
    const screenFieldsMeta = ref([]);

    const setEnvVarActivity = (setEnvVarActivity) => {
        isNewEnvVarModalActive.value = setEnvVarActivity;
    };

    const setUpdateEnvVarActive = (setEnvVarActivity) => {
        isUpdateEnvVarModalActive.value = setEnvVarActivity;
    };

    const setLayoutModalActivity = (setLayoutActivity) => {
        isLayoutModalActive.value = setLayoutActivity;
    };

    const setCurrentLayout = (layout) => {
        currentLayout.value = layout;
    };

    /**
     * @param {string} appId 
     * @param {string} moduleId 
     */
    const fetchModule = async (appId, moduleId) => {
        try {
            const { data: { data: moduleData } } = await fetchModuleByIdService(appId, moduleId);
            const { data, global_variables, ...restModuleData } = moduleData;
            variablesSubStore.globalVariablesInfo.value = global_variables || [];
            moduleDetails.value = restModuleData;
            if (data) {
                shouldAvoidModuleUpdate.value = true;
                moduleGraphStore.nodes = data;
            }
        } catch (err) {
            console.error(err);
        }
    };
    /**
     * @param {string} appId 
     * @param {string} moduleId 
     */
    const updateModule = async (appId, moduleId) => {
        try {
            if (shouldAvoidModuleUpdate.value) {
                shouldAvoidModuleUpdate.value = false;
            } else {
                await updateModuleService(appId, moduleId, {
                    name: moduleDetails.value.name,
                    data: moduleGraphStore.nodes
                });
                await createModuleTag(
                    {
                        data: moduleGraphStore.nodes
                    },
                    moduleId
                );
                checkForLintErrors(moduleId);
            }
        } catch (err) {
            console.error(err);
        }
    };
    const createModule = async (appId, payload) => {
        try {
            const { data: { data } } = await createModuleService(appId, payload);
            await createModuleTag({
                data: payload.data
            }, data.id);
            return data.id;
        } catch (err) {
            console.error(err);
        }
    };
    // @TO-DO's: add typings
    const createModuleTag = async (payload, moduleId, isFirstRun = false) => {
        // try {
        const sessionJSON = localStorage.getItem('vue-session-key');
        let session;
        if (sessionJSON) {
            session = JSON.parse(sessionJSON);
        }
        const response = await createModuleTagService(moduleId, {
            user_id: session.id,
            data: payload.data
        });

        latestVersion.value = response.data.data.name;
        fetchVersions(moduleId);
        // } catch (err) {
        //     console.error(err);
        // }
    };
    const fetchModuleDocuments = async (appId, moduleId) => {
        try {
            const { data: { data } } = await fetchModuleDocumentsService(appId, moduleId);
            moduleDocuments.value = data;
        } catch (err) {
            console.error(err);
        }
    };
    const reset = () => {
        versionsSubStore.resetVersions();
        moduleErrorsSubStore.resetModuleErrors();
        moduleDetails.value = null;
        moduleDocuments.value = null;
    };
    const fetchModuleFieldsMeta = async (appId, moduleId) => {
        try {
            const { data: { data } } = await fetchScreenFieldsMetaService(appId, moduleId);
            screenFieldsMeta.value = data || [];
        } catch (err) {
            console.error(err);
        }
    };

    return {
        ...versionsSubStore,
        ...variablesSubStore,
        ...moduleErrorsSubStore,
        isNewEnvVarModalActive,
        isUpdateEnvVarModalActive,
        isLayoutModalActive,
        currentLayout,
        moduleView,
        moduleDetails,
        shouldAvoidModuleUpdate,
        moduleDocuments,
        moduleId,
        screen,
        screenFieldsMeta,
        setEnvVarActivity,
        setUpdateEnvVarActive,
        setLayoutModalActivity,
        setCurrentLayout,
        createModuleTag,
        fetchModule,
        createModule,
        updateModule,
        fetchModuleDocuments,
        fetchModuleFieldsMeta,
        reset
    };
});
